import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "../components/index.module.css"
import "typeface-dosis"
import Footer from "../components/footer"
import Testimonials from "../components/testimonials"
import SEO from "../components/seo"
import MainHeader from "../components/mainHeader"

export default ({ data }) => {
  //despues de acceder a ellas con graphQL, se guardan las dos fotos posibles
  //para la portada en dos variables, que se van a enviar como props en el
  //MainHeader Component

  const portada = data.portada
  const portadaM = data.portadaM

  return (
    <>
      <MainHeader portadaDesk={portada} portadaMob={portadaM} isIndex={true} />
      <SEO title="Inicio" />
      <main>
        <div className={styles.mainContainer}>
          <div className={styles.imgContainerV}>
            <Img fluid={data.index1.childImageSharp.fluid} />
          </div>
          <div className={styles.textContainer}>
            <p>
              Allinea Pilates es un estudio fiel a las raíces del Método,
              originalmente denominado Contrología. Contrología es la completa
              coordinación de cuerpo, mente y espíritu.
            </p>
            <p>
              Estamos comprometidos con la excelencia, con instructores
              capacitados y entrenados para enseñar al alumno a moverse con
              gracia y facilidad en los diferentes equipos clásicos.
            </p>
            <p>
              Nuestro objetivo es cambiar el estilo de vida de los alumnos,
              enseñándoles que “el movimiento cura”, y así se vuelvan
              independientes y vayan ganando confianza, superando desafíos.
              Cuando el método se aplica correctamente hace que uno se vea, se
              sienta y se mueva mejor.
            </p>
          </div>
          <div className={styles.imgContainerH}>
            <Img fluid={data.index2.childImageSharp.fluid} />
          </div>
          <div className={styles.quoteContainer}>
            <p>
              “En 10 sesiones vas a sentir la diferencia. En 20, vas a ver la
              diferencia y en 30, vas a encontrarte en el camino hacia un cuerpo
              totalmente renovado”
            </p>
            <p>Joseph Pilates</p>
          </div>
        </div>
        <Testimonials />
      </main>
      <Footer />
    </>
  )
}

export const query = graphql`
  query IndexQuery {
    portadaM: file(relativePath: { eq: "index-p-m.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portada: file(relativePath: { eq: "index-p2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    index1: file(relativePath: { eq: "v-index.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    index2: file(relativePath: { eq: "index-2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
