import React from "react"
import ReactDOM from "react"
import styles from "./testimonials.module.css"

const { Component } = React
const { render } = ReactDOM

// Data for carousel
const carouselSlidesData = [
  {
    content: '"Siento como el pilates cambió completamente mi cuerpo"',
    author: "Claudia Bernaola",
  },
  {
    content: '"Nunca sentí los ejercicios de esta forma"',
    author: "Fati Gamarra",
  },
  {
    content: '"Me tomó 8 clases comprender el Teaser"',
    author: "Nahir Gonzales",
  },
  {
    content: '"Siento que tengo El Poder y el control sobre mi cuerpo"',
    author: "Pati Eulerich",
  },
  {
    content: '"Por primera vez veo mis piernas diferentes"',
    author: "Coti Zelaschi",
  },
  {
    content: '"Siento que floto luego de cada sesión"',
    author: "Susy Zamphirópolos",
  },
  {
    content:
      '"Cuando tomé mi primera sesión individual volví a enamorarme del método"',
    author: "Caro Cabrera",
  },
  {
    content: '"Entendí como trabajar mi cuerpo para poder mejorar"',
    author: "Liz Abatte",
  },
  // {
  //   content:
  //     '"Encontre en el pilates clasico la manera de conectarme nuevamente con mi cuerpo. Desde lo mas profundo. Desafiante, buscando ganar fortaleza y confianza en cada clase"',
  //   author: "Violeta Escobar",
  // },
]

// Component for left arrow
class CarouselLeftArrow extends Component {
  render() {
    return (
      <a
        href="#"
        className={[styles.carousel__arrow, styles.carousel__arrowLeft].join(
          " "
        )}
        onClick={this.props.onClick}
      >
        <svg
          width="11"
          height="21"
          viewBox="0 0 11 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.541 20.1006L0.999535 10.5503L10.541 1.00006"
            stroke="#BFA318"
          />
        </svg>
      </a>
    )
  }
}

// Component for right arrow
class CarouselRightArrow extends Component {
  render() {
    return (
      <a
        href="#"
        className={[styles.carousel__arrow, styles.carousel__arrowRight].join(
          " "
        )}
        onClick={this.props.onClick}
      >
        <svg
          width="12"
          height="21"
          viewBox="0 0 12 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L10.5415 10.5503L1 20.1005" stroke="#BFA318" />
        </svg>
      </a>
    )
  }
}

// Component for carousel indicator
class CarouselIndicator extends Component {
  render() {
    return (
      <li className={styles.liIndicator}>
        <a
          className={
            this.props.index == this.props.activeIndex
              ? [
                  styles.carousel__indicator,
                  styles.carousel__indicatorActive,
                ].join(" ")
              : styles.carousel__indicator
          }
          onClick={this.props.onClick}
        />
      </li>
    )
  }
}

// Component for slide
class CarouselSlide extends Component {
  render() {
    return (
      <li
        className={
          this.props.index == this.props.activeIndex
            ? [styles.carousel__slide, styles.carousel__slideActive].join(" ")
            : styles.carousel__slide
        }
      >
        <p className={styles.carouselSlide__content}>
          {this.props.slide.content}
        </p>

        <p>
          <strong className={styles.carouselSlide__author}>
            {this.props.slide.author}
          </strong>
        </p>
      </li>
    )
  }
}

// Carousel component
class Carousel extends Component {
  constructor(props) {
    super(props)

    this.goToSlide = this.goToSlide.bind(this)
    this.goToPrevSlide = this.goToPrevSlide.bind(this)
    this.goToNextSlide = this.goToNextSlide.bind(this)

    this.state = {
      activeIndex: 0,
    }
  }

  goToSlide(index) {
    this.setState({
      activeIndex: index,
    })
  }

  goToPrevSlide(e) {
    e.preventDefault()

    let index = this.state.activeIndex
    let { slides } = this.props
    let slidesLength = slides.length

    if (index < 1) {
      index = slidesLength
    }

    --index

    this.setState({
      activeIndex: index,
    })
  }

  goToNextSlide(e) {
    e.preventDefault()

    let index = this.state.activeIndex
    let { slides } = this.props
    let slidesLength = slides.length - 1

    if (index === slidesLength) {
      index = -1
    }

    ++index

    this.setState({
      activeIndex: index,
    })
  }

  render() {
    return (
      <div className={styles.carousel}>
        <CarouselLeftArrow onClick={e => this.goToPrevSlide(e)} />

        <ul className={styles.carousel__slides}>
          {this.props.slides.map((slide, index) => (
            <CarouselSlide
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              slide={slide}
            />
          ))}
        </ul>

        <CarouselRightArrow onClick={e => this.goToNextSlide(e)} />

        <ul className={styles.carousel__indicators}>
          {this.props.slides.map((slide, index) => (
            <CarouselIndicator
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              onClick={e => this.goToSlide(index)}
            />
          ))}
        </ul>
      </div>
    )
  }
}

const Testimonials = () => {
  return (
    <>
      <section className={styles.section}>
        <div className={styles.carouselContainer}>
          <Carousel slides={carouselSlidesData} />
        </div>
        <a href="https://wa.me/595981597069?text=Hola%20Allinea!%20Estoy%20interesado%20en%20sus%20clases%20">
          <button type="button" className={styles.button}>
            CONSULTAR
          </button>
        </a>
      </section>
    </>
  )
}

export default Testimonials
